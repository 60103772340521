import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HCPService } from "./hcp.service";


import { DEFAULT_PACKAGE_URL_PROVIDER } from "@angular/platform-browser-dynamic/src/compiler_factory";
import { AppConfigService } from "../../app.config";
import { DisplayModes } from "../../models/enums";
import { Specialty } from "../../models/lookups/speciality.model";
import { HCP } from "../../models/lookups/hcp.model";
import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { LookupsService } from "../../services/lookups.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { Page } from "../../models/page.model";
import { ModalDirective } from "ngx-bootstrap";

@Component({
  selector: "app-hcp",
  templateUrl: "./hcp.component.html",
  styleUrls: ["./hcp.component.css"]
})
export class HCPComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  countryId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  specialities: Specialty[] = new Array<Specialty>();
  specialty: Specialty;
  hcps: HCP[] = [];
  error: boolean;
  message: string;
  loading: boolean;
  showHcoHcp: boolean = false;

  public logo = require("../../assets/images/demo/HCP Class.png");


  @ViewChild("specialityId")
  private specialityId;

  @ViewChild("specialitiesSelector")
  private specialitiesSelector;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("hcpHcoProvisionModal")
  hcpHcoProvisionModal: ModalDirective;
  

  @ViewChild("addHcoHcpModal")
  addHcoHcpModal: ModalDirective;

  selectedHcpId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private hcpService: HCPService,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) {

  }

  ngOnInit() {
    this.getSpecialities();
    // this.getHCPCLasses();
    this.columns = [
      { field: 'id', header: 'Code', width: '5%' },
      { field: 'fullName', header: 'Full Name', width: '20%' },
      { field: 'specialityName', header: 'Speciality', width: '11%' },
      { field: 'nickName', header: 'Nick Name', width: '11%' },
      { field: 'email', header: 'Email', width: '15%' },
      { field: 'phone', header: 'Phone', width: '10%' }

    ];
    this.searchHcps();
  }

  searchHcps() {

    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.specialityId = (this.specialty == null) ? 0 : this.specialty.id;
    this.hcpService.SearchHCPs(this.search.searchText, this.specialityId, this.search.hcpClassId, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      let result = response as Page;
      this.hcps = result.items;

      this.hcps.forEach(i => { if (i.fullName && i.fullName.length > 30) { i.trimmedName = i.fullName.substring(0, 25) + '...'; } else i.trimmedName = i.fullName; });
      this.hcps.forEach(i => { if (i.email && i.email.length > 30) { i.trimmedEmail = i.email.substring(0, 25) + '...'; } else i.trimmedEmail = i.email; });

      this.totalItems = result.totalItems;
      //console.log(result);
    }, err => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.first / event.rows + 1;
    this.searchHcps();
  }

  newHCO() {
    this.addNew = true;
    //this.router.navigate(["add-hcp"]);
  }


  gotoEdit(hcoId) {
    this.selectedHcpId = hcoId;
    this.addNew = true;
    //this.router.navigate(["add-hcp", hcoId]);
  }

  confirmDelete(hcpId) {
    this.selectedHcpId = hcpId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteHCP(hcpId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  deleteHCP() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.hcpService.DeleteHCP(this.selectedHcpId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);

      this.searchHcps();
    }, () => {
      this.displayMode = DisplayModes.Success;

      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }
  getSpecialities() {
    this.displayMode = DisplayModes.Loading;
    this.hcpService.GetAllSpecialities("").subscribe(response => {

      const results = response;
      this.specialities = results as Specialty[];
      this.displayMode = DisplayModes.Success;
      //setTimeout(() => this.specialitiesSelector.refresh());
    }, () => {
      this.displayMode = DisplayModes.Error;
    });
  }

  //getHCPCLasses() {
  //  this.displayMode = DisplayModes.Loading;
  //  this.hcpService.GetAllHCPClasses("").subscribe(response => {

  //    let results = response;
  //    this.hcpClasses = results as HCPClass[];
  //    this.displayMode = DisplayModes.Success;
  //   // setTimeout(() => this.hcpClassesSelector.refresh());
  //  }, err => {
  //    this.displayMode = DisplayModes.Error;
  //  });
  //}

  generateOTP(hcpId) {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Generating"));
    this.hcpService.generateOTP(hcpId).subscribe((result) => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("", this.translateService.getTranslation("GeneratedSuccessfully"), MessageSeverity.Success);
      this.hcps.filter(h => h.id == hcpId)[0].otp = result as string;
    }, (err) => {
      console.log(err)
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToGenerate"), MessageSeverity.Error);

    });
  }

  showProvisionModal() {
    this.provisionModal.show();
  }

  onProvisionModalHidden() {

  }

  downloadCSVTemplate() {
    this.hcpService.downloadCsvTemplate().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "HCPTemplate.csv";
      anchor.href = url;
      anchor.click();
    },
      err => { console.log(err); });
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.loading = true;
    this.error = false;
    this.message = "Loading .....";
    this.hcpService.uploadCsvTemplate(formData).subscribe(result => {
      this.loading = false;
      let res = result as any;
      if (res.isError) {
        this.error = true;
        this.message = "uploaded with error";
        this.downloadCSVTemplatErrorFile();
      }
      else
        this.message = "uploaded success";
    });
  }

  downloadCSVTemplatErrorFile() {
    this.hcpService.downloadErrorFile().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "Error.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);
      this.displayMode = DisplayModes.Success;

    },
      err => {
        console.log(err);
        this.displayMode = DisplayModes.Error;
      });
  }

  closeAdding(event) {
    this.selectedHcpId = undefined;
    this.addNew = false;
    if (event) {
      this.searchHcps();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  gotoManageHcpHcos(hcpId) {
    this.selectedHcpId = hcpId;
    this.showHcoHcp = true;
    this.addHcoHcpModal.show();
  }


  onCloseAddHcoHcpModal() {
    this.showHcoHcp = false;
    this.addHcoHcpModal.hide();
  }


  showHcpHcoProvisionModal() {
    this.hcpHcoProvisionModal.show();
  }


  onHcpHcoProvisionModalHidden() {

  }
}
