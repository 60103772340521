// ====================================================

export type PermissionNames =
  "View Users" | "Manage Users" |
  "View Roles" | "Manage Roles" |
  "Assign Roles" | "View Bricks" |
  "Manage Bricks" | "View Lookups" |
  "Manage Lookups" | "View Business Units" |
  "Manage Business Units" | "View Items" |
  "Manage Items" | "View Organization Types" |
  "Manage Organization Types" | "View User HCOs Requests" |
  "Manage Plans" | "View Plans" |
  "View HCP Classes" | "Manage HCP Classes" |
  "View Expense Types" | "Manage Expenses Types" |
  "View Activity Types" | "Manage Activity Types" |
  "View Expenses" | "Manage Expenses" |
  "Create Transaction" | "View Transactions" | "Delete Transactions" |
  "Manage Transactions" | "Track all Transactions" | "Manage Subordinates Transactions" |
  "All Reports" | "Actual Visits Report" | "Total Visits Report" |
  "Coverage Report" | "View User Hcos list" | "Delete User Hco from list"

export type PermissionValues =
  "users.view" | "users.manage" |
  "roles.view" | "roles.manage" |
  "roles.assign" | "bricks.view" |
  "bricks.manage" | "lookups.view" |
  "lookups.manage" | "businessUnits.view" |
  "businessUnits.manage" | "items.view" |
  "items.manage" | "lookups.organizationTypes.view" |
  "lookups.organizationTypes.manage" | "lookups.jobs.view" |
  "lookups.jobs.manage" | "hcos.view" |
  "hcos.manage" | "hcos.requestAdd" | "hcps.view" |
  "hcps.manage" | "cycles.view" |
  "cycles.manage" | "orgJobStructures.view" |
  "orgJobStructures.manage" | "lookups.brickLevels.view" |
  "lookups.brickLevels.manage" | "lookups.specialities.manage" |
  "lookups.specialities.view" | "lookups.brickLevels.manage" |
  "lookups.brickLevels.view" | "lookups.itemTypes.view" |
  "lookups.itemTypes.manage" | "settings.userHcosRequests.view" |
  "plans.manage" | "plans.view" | "plans.delete" | "plans.track" | "plans.create" |
  "plans.review" | "plans.manageSubordinatesPlans" |
  "userHcoRequests.manage" | "userHcoRequests.view" | "userHcoRequests.delete" | "userHcoRequests.track" | "userHcoRequests.create" |
  "userHcoRequests.review" | "userHcoRequests.manageSubordinatesRequests" |
  "hcpClasses.view" | "hcpClasses.manage" |
  "expenseTypes.view" | "expenseTypes.manage" |
  "activityTypes.view" | "activityTypes.manage" |

  "expenses.manage" | "expenses.view" | "expenses.delete" | "expenses.track" | "expenses.create" |
  "expenses.review" | "expenses.manageSubordinatesExpenses" |
  "reports.viewAll" | "reports.viewSubordinates" | "reports.viewSubordinatesBusinessUnit" | "reports.actualVisits" | "reports.totalVisits" | "reports.coverage" | "reports.phVisitPerformance" | "lookups.workingdays.view" |
  "lookups.holiday.view" | "lookups.holiday.manage" | "vacations.view" | "vacations.manage" | "vacations.review" |
  "lookups.vacationtype.view" | "lookups.vacationtype.manage" | "coaching.view" | "coaching.manage" | "topic.view" |
  "topic.manage" | "questionHead.view" | "questionHead.manage" | "question.view" | "question.manage" | "coachingTransaction.view" |
  "coachingTransaction.manage" | "businessUnitTypes.view" | "businessUnitTypes.manage" | "visits.view" | "visits.manage" | "visits.delete" |
  "userHCOs.view" | "hcosLineClass.view" | "doubleVisit.view" | "representation.view" | "reports.pcf"|
  "userHcosList.view" | "userHcosList.delete" | "planReport.view" | "businessUnitStructure.view" | "contents.view" | "contents.manage" | "consents.manage" | "consentTypes.manage" |
  "consents.view" | "consentTypes.view" | "consentForms.view" | "consentForms.add" | "consentForms.edit" | "emailTemplates.manage" | "emailTemplates.view" | "emailTransactions.manage" |
  "emailTransactions.view" | "emailTransactions.manageAll" | "emailTransactions.manageSubordinates" | "reports.specialityClass" | "hcos.delete" | "hcos.edit" | "hcohcps.delete" | "reports.productSpeciality" |
  "reports.productSpecialityClass" | "reports.productOrganizationType" | "reports.vaction" | "reports.nonFiledActivity" | "salesFile.upload" | "settings.manage" |
  "userSampleRequests.view" | "userSampleRequests.delete" | "userSampleRequests.manageAll" | "userSampleRequests.create" | "userSampleRequests.manageSubordinatesRequests" | "reports.sampleStock" | "reports.sampleTracker" | "userSampleRequests.provision" |
  "reports.absent" | "reports.coashingDashboard" | "reports.hcoReaction" | "vacationCredits.view" | "vacationCredits.provision" | "vacationCredits.manageAll" | "vacationCredits.manageSubordinatesRequests" | "reports.vacationCredeit" | "reports.plannedDays"
  | "reports.activity" | "itemFormats.view" | "itemFormats.manage" | "reports.giveawayTracker" | "reports.avgContentSlideTime" | "reports.avgProductWithContent" | "reports.detailingTimeContent" | "reports.fackedVisitLocation" |
  "hcos.changeLocation" | "reports.syncLogErrors" | "reports.upToDateStatus" | "reactions.view" | "reactions.manage" | "reports.visitScript" | "itemBrands.view"
  | "itemBrands.manage" | "activity.view" | "activity.add" | "activity.edit" | "activity.delete" | "activity.review" | "visits.track" | "visits.manageSubordinatesVisits" | "hcos.provision" | "hcos.export" |
  "distributors.view" | "distributors.manage" | "visits.create" | "reports.brickStructure" | "message.view" | "message.create" | "message.edit" | "message.delete" | "reports.managerPerformance"
  | "reports.pendingActions" | "reports.upcomingVisits" | "incentive.manage" | "incentive.create" | "incentive.edit"| "reports.hcoOrgType" | "reports.totalDoubleVisits" | "reports.coachingDetails" | "reports.coachingRate"
  | "pharmacy.view" | "pharmacy.requestAdd" | "pharmacy.add" | "pharmacy.edit" | "pharmacy.delete" | "pharmacy.review" | "pharmacy.changeLocation"
  | "pharmacy.provision" | "pharmacy.export" | "pharmacy.updateSheet" | "userPharmacyRequests.view" | "userPharmacyRequests.delete" | "userPharmacyRequests.create"
  | "userPharmacyRequests.review" | "userPharmacyRequests.trackAll" | "userPharmacyRequests.manageSubordinatesRequests" | "userPharmacyList.view" | "userPharmacyList.delete" |
  "userPharmacyHcoLink.view" | "userPharmacyHcoLink.add" | "userPharmacyHcoLink.delete" | "userPharmacyHcoLink.review" |
  "pharmacyPlans.view" | "pharmacyPlans.delete" | "pharmacyPlans.edit" | "pharmacyPlans.create" | "pharmacyPlans.review" | "pharmacyPlans.trackAll" |
  "pharmacyPlans.manageSubordinatesPlans" | "feedback.view" | "feedback.manage" | "pharmacyVisits.view" | "pharmacyVisits.delete" | "pharmacyVisits.edit" | "pharmacyVisits.create"|
  "pharmacyVisits.trackAll" | "pharmacyVisits.manageSubordinates" | "pharmacyVisits.review" | "reports.pcfPharmacy" | "activityGroup.view" | "activityGroup.manage"
  | "activityCostCenter.view" | "activityCostCenter.manage" | "activityCostType.view" | "activityCostType.manage" | "vendor.view" | "vendor.manage" | "reports.nonSalseKPIs" |
  "reports.returnOnInvestment" | "activity.viewDeleted" | "reports.businessUnitBricksHistory" | "reports.hcoLinkedPharmacies" | "reports.linkedPharmacyStatistics" | "userHcoRequests.manageBusinessUnitRequests"
  | "userBricks.view" | "userBricks.add" | "userBricks.delete"

/*"expenses.view" | "expenses.manage" |*/
export class Permission {

  public static readonly viewUsersPermission: PermissionValues = "users.view";
  public static readonly manageUsersPermission: PermissionValues = "users.manage";
  public static readonly viewRolesPermission: PermissionValues = "roles.view";
  public static readonly manageRolesPermission: PermissionValues = "roles.manage";
  public static readonly assignRolesPermission: PermissionValues = "roles.assign";
  public static readonly viewBricksPermission: PermissionValues = "bricks.view";
  public static readonly manageBricksPermission: PermissionValues = "bricks.manage";
  public static readonly viewBusinessUnitsPermission: PermissionValues = "businessUnits.view";
  public static readonly manageBusinessUnitsPermission: PermissionValues = "businessUnits.manage";
  public static readonly viewHCPClassesPermission: PermissionValues = "hcpClasses.view";
  public static readonly manageHCPClassesPermission: PermissionValues = "hcpClasses.manage";
  public static readonly viewItemsPermission: PermissionValues = "items.view";
  public static readonly manageItemsPermission: PermissionValues = "items.manage";
  public static readonly viewItemFormatsPermission: PermissionValues = "itemFormats.view";
  public static readonly manageItemFormatsPermission: PermissionValues = "itemFormats.manage";
  public static readonly viewItemBrandsPermission: PermissionValues = "itemBrands.view";
  public static readonly manageItemBrandsPermission: PermissionValues = "itemBrands.manage";
  public static readonly viewDistributorsPermission: PermissionValues = "distributors.view";
  public static readonly manageDistributorsPermission: PermissionValues = "distributors.manage";
  public static readonly viewMessagesPermission: PermissionValues = "message.view";
  public static readonly createMessagesPermission: PermissionValues = "message.create";
  public static readonly editMessagesPermission: PermissionValues = "message.edit";
  public static readonly deleteMessagesPermission: PermissionValues = "message.delete";
  public static readonly viewReactionPermission: PermissionValues = "reactions.view";
  public static readonly manageReactionPermission: PermissionValues = "reactions.manage";
  public static readonly viewOrganizationTypesPermission: PermissionValues = "lookups.organizationTypes.view";
  public static readonly manageOrganizationTypesPermission: PermissionValues = "lookups.organizationTypes.manage";
  public static readonly viewJobsPermission: PermissionValues = "lookups.jobs.view";
  public static readonly manageJobsPermission: PermissionValues = "lookups.jobs.manage";
  public static readonly viewHcosPermission: PermissionValues = "hcos.view";
  public static readonly manageHcosPermission: PermissionValues = "hcos.manage";
  public static readonly deleteHcosPermission: PermissionValues = "hcos.delete";
  public static readonly editHcosPermission: PermissionValues = "hcos.edit";
  public static readonly addHcoRequestPermission: PermissionValues = "hcos.requestAdd";
  public static readonly changeHcoLocationPermission: PermissionValues = "hcos.changeLocation";
  public static readonly provisionHcosPermission: PermissionValues = "hcos.provision";
  public static readonly exportHcosPermission: PermissionValues = "hcos.export";
  public static readonly deleteHcoHcpsPermission: PermissionValues = "hcohcps.delete";
  public static readonly viewHcpsPermission: PermissionValues = "hcps.view";
  public static readonly manageHcpsPermission: PermissionValues = "hcps.manage";
  public static readonly viewCyclesPermission: PermissionValues = "cycles.view";
  public static readonly manageCyclesPermission: PermissionValues = "cycles.manage";
  public static readonly viewOrganizationJobStructuresPermission: PermissionValues = "orgJobStructures.view";
  public static readonly manageOrganizationJobStructuresPermission: PermissionValues = "orgJobStructures.manage";
  public static readonly viewBrickLevelsPermission: PermissionValues = "lookups.brickLevels.view";
  public static readonly manageBrickLevelsPermission: PermissionValues = "lookups.brickLevels.manage";
  public static readonly viewSpecialitiesPermission: PermissionValues = "lookups.specialities.view";
  public static readonly manageSpecialitiesPermission: PermissionValues = "lookups.specialities.manage";
  public static readonly viewItemTypesPermission: PermissionValues = "lookups.itemTypes.view";
  public static readonly manageItemTypesPermission: PermissionValues = "lookups.itemTypes.manage";
  public static readonly viewUserHCOsRequestsPermission: PermissionValues = "settings.userHcosRequests.view";
  public static readonly managePlansPermission: PermissionValues = "plans.manage";
  public static readonly viewPlansPermission: PermissionValues = "plans.view";
  public static readonly reviewPlansPermission: PermissionValues = "plans.review";
  public static readonly deletePlansPermission: PermissionValues = "plans.delete";
  public static readonly trackPlansPermission: PermissionValues = "plans.track";
  public static readonly createPlanPermission: PermissionValues = "plans.create";
  public static readonly manageSubordinatesPlansPermission: PermissionValues = "plans.manageSubordinatesPlans";
  public static readonly viewExpenseTypePermission: PermissionValues = "expenseTypes.view";
  public static readonly manageExpenseTypePermission: PermissionValues = "expenseTypes.manage";
  public static readonly viewActivityTypesPermission: PermissionValues = "activityTypes.view";
  public static readonly manageActivityTypesPermission: PermissionValues = "activityTypes.manage";
  public static readonly viewActivityGroupPermission: PermissionValues = "activityGroup.view";
  public static readonly manageActivityGroupPermission: PermissionValues = "activityGroup.manage";
  public static readonly viewActivityCostCenterPermission: PermissionValues = "activityCostCenter.view";
  public static readonly manageActivityCostCenterPermission: PermissionValues = "activityCostCenter.manage";
  public static readonly viewActivityCostTypePermission: PermissionValues = "activityCostType.view";
  public static readonly manageActivityCostTypePermission: PermissionValues = "activityCostType.manage";
  public static readonly viewVendorPermission: PermissionValues = "vendor.view";
  public static readonly manageVendorPermission: PermissionValues = "vendor.manage";

  public static readonly viewActivityPermission: PermissionValues = "activity.view";
  public static readonly addActivityPermission: PermissionValues = "activity.add";
  public static readonly editActivityPermission: PermissionValues = "activity.edit";
  public static readonly deleteActivityPermission: PermissionValues = "activity.delete";
  public static readonly reviewActivityPermission: PermissionValues = "activity.review";
  public static readonly viewDeletedActivitiesPermission: PermissionValues = "activity.viewDeleted";
  //public static readonly viewexpensesPermission: PermissionValues = "expenses.view";
  //public static readonly manageexpensesPermission: PermissionValues = "expenses.manage";
  public static readonly manageExpensesPermission: PermissionValues = "expenses.manage";
  public static readonly viewExpensesPermission: PermissionValues = "expenses.view";
  public static readonly reviewExpensesPermission: PermissionValues = "expenses.review";
  public static readonly deleteExpensesPermission: PermissionValues = "expenses.delete";
  public static readonly trackExpensesPermission: PermissionValues = "expenses.track";
  public static readonly createExpensesPermission: PermissionValues = "expenses.create";
  public static readonly manageSubordinatesExpensesPermission: PermissionValues = "expenses.manageSubordinatesExpenses";
  public static readonly viewAllReportsPermission: PermissionValues = "reports.viewAll";
  public static readonly viewSpecialityClassReportPermission: PermissionValues = "reports.specialityClass";
  public static readonly viewLinkedPharmacyStatisticsReportPermission: PermissionValues = "reports.linkedPharmacyStatistics";
  public static readonly viewProudctSpecialityReportPermission: PermissionValues = "reports.productSpeciality";
  public static readonly viewProudctSpecialityClassReportPermission: PermissionValues = "reports.productSpecialityClass"
  public static readonly viewProudctOrganizationTypeReportPermission: PermissionValues = "reports.productOrganizationType"
  public static readonly viewVacationReportPermission: PermissionValues = "reports.vaction"
  public static readonly viewNonFieldActivityReportPermission: PermissionValues = "reports.nonFiledActivity"
  public static readonly viewSubordinatesReportsPermission: PermissionValues = "reports.viewSubordinates";
  public static readonly viewSubordinatesBusinessUnitReportsPermission: PermissionValues = "reports.viewSubordinatesBusinessUnit";
  public static readonly viewActualVisitsPermission: PermissionValues = "reports.actualVisits";
  public static readonly viewTotalVisitsPermission: PermissionValues = "reports.totalVisits";
  public static readonly viewTotalDoubleVisitsPermission: PermissionValues = "reports.totalDoubleVisits";
  public static readonly viewCoverageReportPermission: PermissionValues = "reports.coverage";
  public static readonly viewPhVisitPerformanceReportPermission: PermissionValues = "reports.phVisitPerformance";
  public static readonly viewSampleStockReportPermission: PermissionValues = "reports.sampleStock";
  public static readonly viewSampleTrackerReportPermission: PermissionValues = "reports.sampleTracker";
  public static readonly viewGiveawayTrackerReportPermission: PermissionValues = "reports.giveawayTracker";
  public static readonly viewAbsentReportPermission: PermissionValues = "reports.absent";
  public static readonly viewCoashingDashboardPermission: PermissionValues = "reports.coashingDashboard";
  public static readonly viewHcoReactionReportPermission: PermissionValues = "reports.hcoReaction";
  public static readonly viewVacationCredeitReportPermission: PermissionValues = "reports.vacationCredeit";
  public static readonly viewPlannedDaysReportPermission: PermissionValues = "reports.plannedDays";
  public static readonly viewActivityReportPermission: PermissionValues = "reports.activity";
  public static readonly viewAvgContentSlideTimeReportPermission: PermissionValues = "reports.avgContentSlideTime";
  public static readonly viewAvgProductWithContentReportPermission: PermissionValues = "reports.avgProductWithContent";
  public static readonly viewDetailingTimeContentReportPermission: PermissionValues = "reports.detailingTimeContent";
  public static readonly viewFackedVisitLocationReportPermission: PermissionValues = "reports.fackedVisitLocation";
  public static readonly viewSyncLogErrorsReportPermission: PermissionValues = "reports.syncLogErrors";
  public static readonly viewUpToDateStatusReportPermission: PermissionValues = "reports.upToDateStatus";
  public static readonly viewVisitScriptReportPermission: PermissionValues = "reports.visitScript";
  public static readonly viewBrickStructureReportPermission: PermissionValues = "reports.brickStructure";
  public static readonly viewManagerPerformanceReportPermission: PermissionValues = "reports.managerPerformance";
  public static readonly viewProductiveCallFreqReportPermission: PermissionValues = "reports.pcf";
  public static readonly viewProductiveCallFreqPharmacyReportPermission: PermissionValues = "reports.pcfPharmacy";
  public static readonly viewCoachingRateReportPermission: PermissionValues = "reports.coachingRate";
  public static readonly viewCoachingDetailsReportPermission: PermissionValues = "reports.coachingDetails";
  public static readonly viewPendingActionsPermission: PermissionValues = "reports.pendingActions";
  public static readonly viewUpcomingVisitsPermission: PermissionValues = "reports.upcomingVisits";
  public static readonly viewHcoOrgTypeReportPermission: PermissionValues = "reports.hcoOrgType";
  public static readonly viewNonSalesKpisReportPermission: PermissionValues = "reports.nonSalseKPIs";
  public static readonly viewReturnOnInvestmentReportPermission: PermissionValues = "reports.returnOnInvestment";
  public static readonly viewBusinessUnitBricksHistoryReportPermission: PermissionValues = "reports.businessUnitBricksHistory";
  public static readonly viewHcoLinkedPharmaciesReportPermission: PermissionValues = "reports.hcoLinkedPharmacies";
  public static readonly viewWorkingDaysPermission: PermissionValues = "lookups.workingdays.view";
  public static readonly viewHolidayPermission: PermissionValues = "lookups.holiday.view";
  public static readonly manageHolidayPermission: PermissionValues = "lookups.holiday.manage";
  public static readonly viewVacationPermission: PermissionValues = "vacations.view";
  public static readonly manageVacationPermission: PermissionValues = "vacations.manage";
  public static readonly reviewVacationPermission: PermissionValues = "vacations.review";
  public static readonly viewVacationTypePermission: PermissionValues = "lookups.vacationtype.view";
  public static readonly manageVacationTypePermission: PermissionValues = "lookups.vacationtype.manage";
  public static readonly viewCoachingPermission: PermissionValues = "coaching.view";
  public static readonly manageCoachingPermission: PermissionValues = "coaching.manage";
  public static readonly viewTopicPermission: PermissionValues = "topic.view";
  public static readonly manageTopicPermission: PermissionValues = "topic.manage";
  public static readonly viewQuestionHeadPermission: PermissionValues = "questionHead.view";
  public static readonly manageQuestionHeadPermission: PermissionValues = "questionHead.manage";
  public static readonly viewQuestionPermission: PermissionValues = "question.view";
  public static readonly manageQuestionPermission: PermissionValues = "question.manage";
  public static readonly viewCoachingTransactionsPermission: PermissionValues = "coachingTransaction.view";
  public static readonly manageCoachingTransactionsPermission: PermissionValues = "coachingTransaction.manage";


  public static readonly viewBusinessUnitTypesPermission: PermissionValues = "businessUnitTypes.view";
  public static readonly manageBusinessUnitTypesPermission: PermissionValues = "businessUnitTypes.manage";
  public static readonly viewVisitPermission: PermissionValues = "visits.view";
  public static readonly manageVisitPermission: PermissionValues = "visits.manage";
  public static readonly deleteVisitPermission: PermissionValues = "visits.delete";
  public static readonly trackVisitPermission: PermissionValues = "visits.track";
  public static readonly createVisitPermission: PermissionValues = "visits.create";
  public static readonly manageSubordinatesVisitsPermission: PermissionValues = "visits.manageSubordinatesVisits";

  public static readonly manageUserHcoRequestPermission: PermissionValues = "userHcoRequests.manage";
  public static readonly viewUserHcoRequestPermission: PermissionValues = "userHcoRequests.view";
  public static readonly reviewUserHcoRequestsPermission: PermissionValues = "userHcoRequests.review";
  public static readonly deleteUserHcoRequestPermission: PermissionValues = "userHcoRequests.delete";
  public static readonly trackUserHcoRequestsPermission: PermissionValues = "userHcoRequests.track";
  public static readonly createUserHcoRequestPermission: PermissionValues = "userHcoRequests.create";
  public static readonly manageBusinessUnitRequestsPermission: PermissionValues = "userHcoRequests.manageBusinessUnitRequests"
  public static readonly manageSubordinatesUserHcoRequestsPermission: PermissionValues = "userHcoRequests.manageSubordinatesRequests";
  public static readonly viewUserHCOsPermission: PermissionValues = "userHCOs.view";
  public static readonly viewHCOsLineClassPermission: PermissionValues = "hcosLineClass.view";
  public static readonly viewDoubleVisitPermission: PermissionValues = "doubleVisit.view";
  public static readonly ViewRepresentationsPermission: PermissionValues = "representation.view";
  public static readonly viewUserHcosListPermission: PermissionValues = "userHcosList.view";
  public static readonly deleteUserHcoPermission: PermissionValues = "userHcosList.delete";
  public static readonly ViewPlansReportPermission: PermissionValues = "planReport.view";
  public static readonly ViewBusinessUnitStructurePermission: PermissionValues = "businessUnitStructure.view";
  public static readonly viewContents: PermissionValues = "contents.view";
  public static readonly manageContents: PermissionValues = "contents.manage";
  public static readonly viewConsents: PermissionValues = "consents.view";
  public static readonly manageConsents: PermissionValues = "consents.manage";
  public static readonly viewConsentTypes: PermissionValues = "consentTypes.view";
  public static readonly manageConsentTypes: PermissionValues = "consentTypes.manage";
  public static readonly viewConsentForms: PermissionValues = "consentForms.view";
  public static readonly addConsentForms: PermissionValues = "consentForms.add";
  public static readonly editConsentForms: PermissionValues = "consentForms.edit";

  public static readonly manageEmailTemplates: PermissionValues = "emailTemplates.manage";
  public static readonly viewEmailTemplates: PermissionValues = "emailTemplates.view";
  public static readonly manageEmailTransactions: PermissionValues = "emailTransactions.manage";
  public static readonly viewEmailTransactions: PermissionValues = "emailTransactions.view";
  public static readonly manageAllEmailTransactions: PermissionValues = "emailTransactions.manageAll";
  public static readonly manageSubordinatesEmailTransactions: PermissionValues = "emailTransactions.manageSubordinates";
  public static readonly uploadSalesFile: PermissionValues = "salesFile.upload";
  public static readonly manageSettings: PermissionValues = "settings.manage";
  public static readonly viewUserSampleRequests: PermissionValues = "userSampleRequests.view";
  public static readonly deleteUserSampleRequests: PermissionValues = "userSampleRequests.delete";
  public static readonly manageAllUserSampleRequests: PermissionValues = "userSampleRequests.manageAll";
  public static readonly createUserSampleRequests: PermissionValues = "userSampleRequests.create";
  public static readonly manageSubUserSampleRequests: PermissionValues = "userSampleRequests.manageSubordinatesRequests";
  public static readonly provisionUserSampleRequests: PermissionValues = "userSampleRequests.provision";

  public static readonly viewVacationCredits: PermissionValues = "vacationCredits.view";
  public static readonly manageVacationCredits: PermissionValues = "vacationCredits.manageAll";
  public static readonly manageSubordinateVacationCredits: PermissionValues = "vacationCredits.manageSubordinatesRequests";
  public static readonly provisionVacationCredits: PermissionValues = "vacationCredits.provision";
  public static readonly manageIncentive: PermissionValues = "incentive.manage";
  public static readonly createIncentive: PermissionValues = "incentive.create";
  public static readonly editIncentive: PermissionValues = "incentive.edit";

  public static readonly viewPharmaciesPermission: PermissionValues = "pharmacy.view";
  public static readonly requestAddPharmaciesPermission: PermissionValues = "pharmacy.requestAdd";
  public static readonly addPharmacyPermission: PermissionValues = "pharmacy.add";
  public static readonly editPharmacyPermission: PermissionValues = "pharmacy.edit";
  public static readonly deletePharmacyPermission: PermissionValues = "pharmacy.delete";
  public static readonly reviewPharmaciesPermission: PermissionValues = "pharmacy.review";
  public static readonly changeLocationPharmacyPermission: PermissionValues = "pharmacy.changeLocation";
  public static readonly provisionPharmaciesPermission: PermissionValues = "pharmacy.provision";
  public static readonly exportPharmaciesPermission: PermissionValues = "pharmacy.export";
  public static readonly updateSheetPharmaciesPermission: PermissionValues = "pharmacy.updateSheet";

  public static readonly viewUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.view";
  public static readonly deleteUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.delete";
  public static readonly createUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.create";
  public static readonly reviewUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.review";
  public static readonly trackAllUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.trackAll";
  public static readonly manageSubordinateUserPharmacyRequestsPermission: PermissionValues = "userPharmacyRequests.manageSubordinatesRequests";

  public static readonly viewUserPharmacyListPermission: PermissionValues = "userPharmacyList.view";
  public static readonly deleteUserPharmacyListPermission: PermissionValues = "userPharmacyList.delete";

  public static readonly viewUserPharmacyHcoLinkPermission: PermissionValues = "userPharmacyHcoLink.view";
  public static readonly addUserPharmacyHcoLinkPermission: PermissionValues = "userPharmacyHcoLink.add";
  public static readonly deleteUserPharmacyHcoLinkPermission: PermissionValues = "userPharmacyHcoLink.delete";
  public static readonly reviewUserPharmacyHcoLinkPermission: PermissionValues = "userPharmacyHcoLink.review";

  public static readonly editPharmacyPlansPermission: PermissionValues = "pharmacyPlans.edit";
  public static readonly viewPharmacyPlansPermission: PermissionValues = "pharmacyPlans.view";
  public static readonly reviewPharmacyPlansPermission: PermissionValues = "pharmacyPlans.review";
  public static readonly deletePharmacyPlansPermission: PermissionValues = "pharmacyPlans.delete";
  public static readonly trackAllPharmacyPlansPermission: PermissionValues = "pharmacyPlans.trackAll";
  public static readonly createPharmacyPlanPermission: PermissionValues = "pharmacyPlans.create";
  public static readonly manageSubordinatesPharmacyPlansPermission: PermissionValues = "pharmacyPlans.manageSubordinatesPlans";

  public static readonly viewFeedbackPermission: PermissionValues = "feedback.view";
  public static readonly manageFeedbackPermission: PermissionValues = "feedback.manage";

  public static readonly viewPharmacyVisitPermission: PermissionValues = "pharmacyVisits.view";
  public static readonly editPharmacyVisitPermission: PermissionValues = "pharmacyVisits.edit";
  public static readonly deletePharmacyVisitPermission: PermissionValues = "pharmacyVisits.delete";
  public static readonly trackAllPharmacyVisitPermission: PermissionValues = "pharmacyVisits.trackAll";
  public static readonly createPharmacyVisitPermission: PermissionValues = "pharmacyVisits.create";
  public static readonly manageSubordinatesPharmacyVisitsPermission: PermissionValues = "pharmacyVisits.manageSubordinates";
  public static readonly reviewPharmacyVisitPermission: PermissionValues = "pharmacyVisits.review";

  public static readonly viewUserBricksPermission: PermissionValues = "userBricks.view";
  public static readonly addUserBricksPermission: PermissionValues = "userBricks.add";
  public static readonly deleteUserBricksPermission: PermissionValues = "userBricks.delete";

  //Visit
  constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name: PermissionNames;
  public value: PermissionValues;
  public groupName: string;
  public description: string;
}
