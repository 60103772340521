import { BusinessUnit } from "./business-unit.model";

export class Item {
  id: number;
  name: string;
  itemTypeId: number;
  itemTypeName: string;
  itemFormatId: number;
  itemBrandId: number;
  itemFormatName: string;
  brandName: string;
  itemBrandName: string;
  publicPrice : number;
  xFactorPrice: number;
  tenderPrice : number;
  exportPrice : number;
  averagePrice: number;
  buisnessUnitId: number;
  businessUnitName: string;
  businessUnit: BusinessUnit;
  isActive: boolean;
}
