import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "./configuration.service";
import { EndpointFactory } from "./endpoint-factory.service";
import { HCO } from "../models/lookups/hco.model";

@Injectable()
export class LookupsService extends EndpointFactory {


  culture: string = "en";

  constructor(protected http: HttpClient,
    configurations: ConfigurationService, injector: Injector) {
    super(http, configurations, injector);

    this.culture = this.configurations.language;
  }

  searchGovernorates(countyId: number, searchText: string, pageSize: number, pageNumber: number) {
    const myParams = new HttpParams()
      .set("countryId", countyId == undefined ? "" : countyId.toString())
      .set("searchText", searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + "/api/lookups/governorates/search", { params: myParams });
  }

  getAllCountries() {
    return this.http.get(this.configurations.apiAddress + "/api/lookups/getAllCountries");
  }
  searchHcOs(searchText: string, brickId: number, organizationTypeId: number, specialityId: number, excludedHcoId: number, pageSize: number, pageNumber: number, buIds:string="") {
    let myParams = new HttpParams()
      .set("brickId", brickId == undefined ? "0" : brickId.toString())
      .set("specialityId", specialityId == undefined ? "0" : specialityId.toString())
      .set("organizationTypeId", organizationTypeId == undefined ? "0" : organizationTypeId.toString())
      .set("excludedHcoId", excludedHcoId == undefined ? "0" : excludedHcoId.toString())
      .set("searchText", searchText == undefined ? "" : searchText)
      .set("pageSize", pageSize.toString())
      .set("buIdsString", buIds)
      .set("pageNumber", pageNumber.toString());
    //.set("pageSize", pageSize == undefined?"":  pageSize.toString());
    return this.http.get(this.configurations.apiAddress + "/api/hco/search", { params: myParams });
  }
  saveHco(hco: HCO) {
    return this.http.post(this.configurations.apiAddress + "/api/hco/save", hco);
  }
  getAllLocations(searchText: string) {
    return this.http.get(this.configurations.apiAddress + "/api/location/getAll/" + searchText);
  }
  getAllOrganizationTypes(searchText: string) {
    return this.http.get(this.configurations.apiAddress + "/api/organizationType/getAll/" + searchText);
  }
  getAllSpecialitys(searchText: string) {
    return this.http.get(this.configurations.apiAddress + "/api/Speciality/getAll/" + searchText);
  }
  getAllClasses() {
    return this.http.get(this.configurations.apiAddress + "/api/HCPClass/getAll");
  }
  getBusinessUnitsRootLevel() {
    return this.http.get(this.configurations.apiAddress + "/api/BusinessUnit/getBusinessUnitsRootLevel");
  }
  getUserBusinessUnitsRootLevel() {
    return this.http.get(this.configurations.apiAddress + "/api/BusinessUnit/getUserBusinessUnitsRootLevel");
  }
  getHcoById(id: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getById/" + id);
  }
  getHcoCardById(id: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getHcoCardById/" + id);
  }
  getVisitsByHco(id: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/getVisitsByHco/" + id);
  }
  changeHcoLocation(hcoId: number,visitId) {
    return this.http.get(this.configurations.apiAddress + "/api/hco/changeHcoLocation/" + hcoId + "/" + visitId);
  }
  deleteHco(id: number) {
    return this.http.delete(this.configurations.apiAddress + "/api/hco/delete/" + id);
  }
  searchOrganizationType(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set("searchText", searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());

    return this.http.get(this.configurations.apiAddress + "/api/organizationType/search", {

      params: myParams
    });
  }


  getAllBricks() {
    return this.http.get(this.configurations.apiAddress + "/api/Bricks/getAllBricks");
  }

  getAllBrickLevels() {
    return this.http.get(this.configurations.apiAddress + "/api/BrickLevel/getAllBrickLeveles");
  }

  getAllLeafBricks() {
    return this.http.get(this.configurations.apiAddress + "/api/Bricks/getBricksLeafLevel");
  }
  mergeHCOs(hcoId: number, HCOs: HCO[]) {
    return this.http.post(this.configurations.apiAddress + "/api/hco/merge/" + hcoId, HCOs);
  }
}
