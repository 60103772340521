import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { HcoService } from '../../hco/hco.service';
import { HCP } from "../../../../models/lookups/hcp.model";
import { HCPHCO } from "../../../../models/lookups/hcpHco.model";
import { DisplayModes } from "../../../../models/enums";
import { AppConfigService } from "../../../../app.config";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap";
import { HCO } from '../../../../models/lookups/hco.model';
import { LookupsService } from '../../../../services/lookups.service';
import { Page } from '../../../../models/page.model';

@Component({
  selector: 'crm-add-hco-hcp',
  templateUrl: './add-hco-hcp.component.html',
  styleUrls: ['./add-hco-hcp.component.css']
})
export class AddHcoHcpComponent implements OnInit {
  @ViewChild(ModalDirective) modal: ModalDirective;

  @Input() hcpId: number;
  @Output() closeModalEvent = new EventEmitter<boolean>();

  hcos: HCO[] = [];
  hcpHcos: HCPHCO[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  currentPage: number = 1;
  pageSize = this.appConfig.settings.pageSize;
  firstList: boolean = true;
  totalItems: number = 0;
  selectedHcosIds: number[] = [];
  searchText;
  constructor(private hcoService: HcoService, private lookupsService: LookupsService,
    private readonly appConfig: AppConfigService) {
  }

  ngOnInit() {
    if (this.hcpId != undefined)
      this.getHcpHcos(this.hcpId);
  }

  getHcos() {
    this.lookupsService.searchHcOs(this.searchText, undefined, undefined, undefined, null, this.pageSize, this.currentPage).subscribe(response => {
      let results = response as Page;
      this.hcos = [];

      results.items.forEach(x => {
        if (this.hcos.filter(y => y.id == x.id).length == 0)
          this.hcos.push(x);
      })
      this.totalItems = results.totalItems;
      this.displayMode = DisplayModes.Success;
      if (this.hcpHcos && this.hcos) {
        this.hcos.forEach((hco) => {
          if (this.hcpHcos.find(x => x.hcoId == hco.id) || this.selectedHcosIds.find(x => x == hco.id)) {
            hco.selected = true;
            if (this.selectedHcosIds.indexOf(hco.id) == -1) {
              this.selectedHcosIds.push(hco.id);
            }
          }
        })
      }

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.first / event.rows + 1;
    if (!this.firstList)
      this.getHcos();
    else
      this.firstList = !this.firstList;
  }

  onSelectHco(hco) {
    if (hco.selected)
      this.selectedHcosIds.push(hco.id);
    else {
      let index = this.selectedHcosIds.indexOf(hco.id);
      if (index > -1)
        this.selectedHcosIds.splice(index, 1);
    }

    console.log(this.selectedHcosIds)
  }

  save(f) {
    let selectedHCos: any[] = [];
    this.selectedHcosIds.forEach(function (hc) { selectedHCos.push(hc); })
    this.hcoService.addHcpHcos(this.hcpId, selectedHCos).subscribe(response => {

      this.displayMode = DisplayModes.Success;
      this.closeModalEvent.emit();
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  getHcpHcos(hcpId) {
    this.hcoService.getHcpHcosByHcpId(hcpId).subscribe(response => {
      let results = response;
      this.hcpHcos = results as HCPHCO[];
      this.getHcos();

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  back() {
    this.closeModalEvent.emit();
  }

}
