import { Injectable, Injector } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { AppTranslationService } from "../../services/app-translation.service";
import { ConfigurationService } from "../../services/configuration.service";
import { LocalStoreManager } from "../../services/local-store-manager.service";
import { DBkeys } from "../../services/db-Keys";
import { EndpointFactory } from "../../services/endpoint-factory.service";
import { HCP } from "../../models/lookups/hcp.model";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class HCPService extends EndpointFactory {

 
  //headers = new HttpHeaders();
  //culture: string = "ar";

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) {
    super(http, configurations, injector);

  
    //this.headers = new HttpHeaders({
    //  'Authorization': "Bearer " + this.localStorage.getData(DBkeys.ACCESS_TOKEN),
    //  'Content-Type': "application/json",
    //  'Accept': `application/vnd.iman.v${EndpointFactory.apiVersion}+json, application/json, text/plain, */*`,
    //  'App-Version': ConfigurationService.appVersion,
    //  'Access-Control-Allow-Origin': "*",
    //  'Access-Control-Allow-Methods': "GET,POST,PATCH,PUT,DELETE,OPTIONS",
    //  'Access-Control-Allow-Headers': "Origin, Content-Type, X-Auth-Token"
    //});
    //this.culture = this.configurations.language;
  }


  SearchHCPs(searchText: string, specialityId: number, hcpClassId: number, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set("specialityId", specialityId == undefined ? "0" : specialityId.toString())
      .set("hcpClassId", hcpClassId == undefined ? "0" : hcpClassId.toString())
      .set("searchText", searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + "/api/hcp/search", {params: myParams });
  }

  SaveHCP(hcp: HCP) {
    return this.http.post(this.configurations.apiAddress + "/api/hcp/save", hcp);
  }

  GetAllHCPClasses(searchText: string) {
    return this.http.get(this.configurations.apiAddress + "/api/hcp/getAllHCPCLasses/" + searchText);
  }

  GetAllSpecialities(searchText: string) {
    return this.http.get(this.configurations.apiAddress + "/api/speciality/getAll/" + searchText);
  }
  GetHCPById(id: number) {
    return this.http.get(this.configurations.apiAddress + "/api/hcp/getById/" + id);
  }
  DeleteHCP(id: number) {
    return this.http.delete(this.configurations.apiAddress + "/api/hcp/delete/" + id);
  }


  generateOTP(hcpId) {
    return this.http.get(this.configurations.apiAddress + "/api/HCP/generateOTPCode/" + hcpId, { responseType: 'text' });
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/HCP/downloadCSVTemplate", { responseType: "blob" });
  }

  uploadCsvTemplate(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/HCP/uploadCSVTemplate", formData);
   
  }

  downloadErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/HCP/downloadErrorFile", { responseType: "blob" });
  }

}
