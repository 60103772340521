<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'HCPs' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'HCPs' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'HCPs Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-3">
          <input type="text"
                 name="searchText"
                 [(ngModel)]="search.searchText"
                 placeholder="{{'SearchText' |translate}}"
                 #name="ngModel"
                 class="form-control" />
        </div>
        <div class="col-sm-3">
          <span class="ui-fluid">
            <p-dropdown [options]="specialities"
                        (onChange)="searchHcps()"
                        styleClass="wid100"
                        placeholder="{{'Speciality' | translate}}"
                        emptyFilterMessage="No results found"
                        showClear="true"
                        [(ngModel)]="specialty"
                        [filter]="true" optionLabel="name"></p-dropdown>

          </span>
        </div>
        <div class="col-sm-2 margin-top-10 cursor-pointer" (click)="searchHcps()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-2 margin-top-10 left-10 cursor-pointer" (click)="newHCO()">
          <span class="margin-right-7 create-new-span">Create New Hcp</span>
          <i class="fa fa-plus-circle"></i>
        </div>
        <div class="col-sm-2 cursor-pointer left-6 margin-top-10" (click)="showHcpHcoProvisionModal()">
          <span class="margin-right-7 create-new-span" >Provision Hcos</span>
          <i class="fa fa-upload" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-hcp *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [hcpId]="selectedHcpId"></crm-add-hcp>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'HCPs Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-table #dt [value]="hcps" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize" [totalRecords]="totalItems"
                   [lazy]="true" (onLazyLoad)="pageChanged($event)">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" width={{col.width}} style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="7%" style="text-align:center" class="table-edit">HCOs</th>
                <th width="5%" style="text-align:center" class="table-edit">Edit</th>
                <th width="5%" style="text-align:center" class="table-edit">Delete</th>
                <th width="5%" style="text-align:center" class="table-edit">Generate OTP Code</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-hcp>
              <tr *ngIf="hcps.length>0 && displayMode==2">
                <!--<td>
                  <strong>{{hcp.id}}</strong>
                </td>-->
                <td>{{ hcp.id }}</td>
                <td>{{ hcp.trimmedName }}</td>
                <td>{{ hcp.specialityName }}</td>
                <td>{{ hcp.nickName }}</td>
                <td>{{ hcp.trimmedEmail }}</td>
                <td>{{ hcp.phone }}</td>
                <td> <a class="" href="javascript:;" (click)="gotoManageHcpHcos(hcp.id)"><i class="fa fa-user-circle-o fa-lg font-size-20"></i></a></td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(hcp.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDelete(hcp.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
                <td><a href="javascript:;" (click)="generateOTP(hcp.id)"><i class="fa fa-plus-circle  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="hcps.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>

        </div>

      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteHCP()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Hcp Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ul id="uidemo-tabs-default-demo" class="nav nav-tabs">
        <li class="active">
          <a href="#uidemo-tabs-default-demo-home" data-toggle="tab">{{'DownloadTemplate' |translate}}</a>
        </li>
        <li class="">
          <a href="#uidemo-tabs-default-demo-profile" data-toggle="tab">{{'UploadTemplate' |translate}}</a>
        </li>

      </ul>

      <div class="tab-content tab-content-bordered">
        <div class="tab-pane fade active in" id="uidemo-tabs-default-demo-home">
          <div class="image-upload cursor-pointer margin-top-5 margin-left-44" (click)="downloadCSVTemplate()">
            <label for="file-download">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <i class="fa fa-arrow-down" aria-hidden="true"></i>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </label>
          </div>

        </div>
        <div class="tab-pane fade" id="uidemo-tabs-default-demo-profile">

          <div class="image-upload cursor-pointer margin-top-5 margin-left-44">
            <label for="file-upload">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </label>

            <input id="file-upload" #file type="file" (change)="upload(file.files)" />
          </div>
          <br />

          <span style="font-weight:bold;color:blue;" *ngIf="message&&loading">
            {{message}}
          </span>

          <span style="font-weight:bold;color:red;" *ngIf="message&&error&&!loading">
            {{message}}
          </span>

          <span style="font-weight:bold;color:green;" *ngIf="message&&!error&&!loading">
            {{message}}
          </span>



        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-sizes-1" bsModal #addHcoHcpModal="bs-modal" (onHidden)="onCloseAddHcoHcpModal()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'HCOs' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">
        <crm-add-hco-hcp *ngIf="showHcoHcp" [hcpId]="selectedHcpId" (closeModalEvent)="onCloseAddHcoHcpModal()"></crm-add-hco-hcp>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-sizes-1" bsModal #hcpHcoProvisionModal="bs-modal" (onHidden)="onHcpHcoProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Hcp Hcos Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="hcpHcoProvisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-hcp-hco #hcpHcoProvisionCSV></crm-provision-hcp-hco>
    </div>
  </div>
</div>
