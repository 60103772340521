<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!vendorId">Create {{'Vendor' | translate}}</span>
    <span class="panel-title" *ngIf="vendorId">Edit {{'Vendor' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (vendorNa.dirty || vendorNa.touched || f.submitted)  && vendorNa.valid, 'has-error' : (vendorNa.dirty || vendorNa.touched || f.submitted)  && vendorNa.invalid}">
              <label class="control-label">{{'clm.name' | translate}} </label>
              <input class="form-control" placeholder="{{'clm.name' | translate}}" required
                     name="vendorNa" #vendorNa="ngModel"
                     [(ngModel)]="vendor.name" type="text">
              <div *ngIf="(vendorNa.dirty || vendorNa.touched || f.submitted)  && vendorNa.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (comercialRec.dirty || comercialRec.touched || f.submitted)  && comercialRec.valid, 'has-error' : (comercialRec.dirty || comercialRec.touched || f.submitted)  && vendorNa.invalid}">
              <label class="control-label">{{'Comercial Record' | translate}} </label>
              <input class="form-control" placeholder="{{'Comercial Record' | translate}}" required
                     name="comercialRec" #comercialRec="ngModel"
                     [(ngModel)]="vendor.comercialRecord" type="text">
              <div *ngIf="(comercialRec.dirty || comercialRec.touched || f.submitted)  && comercialRec.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (taxCa.dirty || taxCa.touched || f.submitted)  && taxCa.valid, 'has-error' : (taxCa.dirty || taxCa.touched || f.submitted)  && taxCa.invalid}">
              <label class="control-label">{{'Tax Card' | translate}} </label>
              <input class="form-control" placeholder="{{'Tax Card' | translate}}" required
                     name="taxCa" #taxCa="ngModel"
                     [(ngModel)]="vendor.taxCard" type="text">
              <div *ngIf="(taxCa.dirty || taxCa.touched || f.submitted)  && taxCa.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (bankAccount.dirty || bankAccount.touched || f.submitted)  && bankAccount.valid, 'has-error' : (bankAccount.dirty || bankAccount.touched || f.submitted)  && bankAccount.invalid}">
              <label class="control-label">{{'Bank Account No' | translate}} </label>
              <input class="form-control" placeholder="{{'Bank Account No' | translate}}" required
                     name="bankAccount" #bankAccount="ngModel"
                     [(ngModel)]="vendor.bankAccountNo" type="text">
              <div *ngIf="(bankAccount.dirty || bankAccount.touched || f.submitted)  && bankAccount.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (bankNa.dirty || bankNa.touched || f.submitted)  && bankNa.valid, 'has-error' : (bankNa.dirty || bankNa.touched || f.submitted)  && bankNa.invalid}">
              <label class="control-label">{{'Bank Name' | translate}} </label>
              <input class="form-control" placeholder="{{'Bank Name' | translate}}" required
                     name="bankNa" #bankNa="ngModel"
                     [(ngModel)]="vendor.bankName" type="text">
              <div *ngIf="(bankNa.dirty || bankNa.touched || f.submitted)  && bankNa.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (branch.dirty || branch.touched || f.submitted)  && branch.valid, 'has-error' : (branch.dirty || branch.touched || f.submitted)  && branch.invalid}">
              <label class="control-label">{{'Branch No' | translate}} </label>
              <input class="form-control" placeholder="{{'Branch No' | translate}}" required
                     name="branch" #branch="ngModel"
                     [(ngModel)]="vendor.branchNo" type="text">
              <div *ngIf="(branch.dirty || branch.touched || f.submitted)  && branch.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (addedValueRegestration.dirty || addedValueRegestration.touched || f.submitted)  && addedValueRegestration.valid, 'has-error' : (addedValueRegestration.dirty || addedValueRegestration.touched || f.submitted)  && addedValueRegestration.invalid}">
              <label class="control-label">{{'Added Value Regestration No' | translate}} </label>
              <input class="form-control" placeholder="{{'Added Value Regestration No' | translate}}" required
                     name="addedValueRegestration" #addedValueRegestration="ngModel"
                     [(ngModel)]="vendor.addedValueRegestrationNo" type="text">
              <div *ngIf="(addedValueRegestration.dirty || addedValueRegestration.touched || f.submitted)  && addedValueRegestration.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (active.dirty || active.touched || f.submitted)  && active.valid, 'has-error' : (active.dirty || active.touched || f.submitted)  && active.invalid}">
              <label class="control-label">{{'Active' | translate}} </label>
              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" #active="ngModel" name="active" [(ngModel)]="vendor.active">{{'Active' | translate}}  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <ngx-dropzone (change)="onFileSelect($event)">
            <ngx-dropzone-label>Drop file, here!</ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of vendor.files" [removable]="true" (removed)="onFileRemove(f)">
              <ngx-dropzone-label>{{ f.filaname }} ({{ f.contentType }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </div>
        <div class="col-sm-12">
          <a *ngFor="let f of vendor.files" href="javascript:void(0)" (click)="openFile(f.fileUrl)" style="margin-right: 10%;">Preview File</a>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" [disabled]="f.invalid">{{'Save' | translate}}</button>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




