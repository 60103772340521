
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { JobsComponent } from "./jobs/jobs.component";
import { SaveJobComponent } from "./jobs/save-job/save-job.component";
import { AuthGuard } from "../services/auth-guard.service";
import { AuthService } from "../services/auth.service";
import { ItemTypeComponent } from "./item-type/item-type.component";
import { AddItemTypeComponent } from "./item-type/add-item-type/add-item-type.component";
import { hcpClassComponent } from "./hcpClass/hcpClass.component";
import { HCPComponent } from "./hcp/hcp.component";
import { AddHCPComponent } from "./hcp/add-hcp/add-hcp.component";
import { SaveSpecialityComponent } from "./speciality/save-speciality/save-speciality.component";
import { BusinessUnitComponent } from "./business-unit/business-unit.component";
import { SaveBusinessUnitComponent } from "./business-unit/save-business-unit/save-business-unit.component";
import { BusinessUnitBricksComponent } from "./business-unit-bricks/business-unit-bricks.component";
import { ManageBusinessUnitBricksComponent } from "./business-unit-bricks/manage-business-unit-bricks/manage-business-unit-bricks.component";
import { BusinessUnitTypeComponent } from "./business-unit-type/business-unit-type.component";
import { SaveBusinessUnitTypeComponent } from "./business-unit-type/save-business-unit-type/save-business-unit-type.component";
import { ItemComponent } from "./item/item.component";
import { AddItemComponent } from "./item/add-item/add-item.component";
import { OrganizationJobStructureComponent } from "./organization-job-structure/organization-job-structure.component";
import { AddOrganizationJobStructureComponent } from "./organization-job-structure/add-organization-job-structure/add-organization-job-structure.component";
import { OrganizationTypeComponent } from "./organization-type/organization-type.component";
import { AddOrganizationTypeComponent } from "./organization-type/add-organization-type/add-organization-type.component";
import { SpecialityComponent } from "./speciality/speciality.component";
import { AddhcpClassComponent } from "./hcpClass/add-hcpClass/add-hcpClass.component";
import { ActivityTypeComponent } from "./activity-type/activity-type.component";
import { AddActivityTypeComponent } from "./activity-type/add-activity-type/add-activity-type.component";
import { WorkingDaysComponent } from "./working-days/working-days.component";
import { holidayComponent } from "./holiday/holiday.component";
import { AddHolidayComponent } from "./holiday/add-holiday/add-holiday.component";
import { VacationTypeComponent } from "./vacation-type/vacation-type.component";
import { VacationTypeAddComponent } from "./vacation-type/vacation-type-add/vacation-type-add.component";
import { CoachingComponent } from "./coaching/coaching.component";
import { AddCoachingComponent } from "./coaching/add-coaching/add-coaching.component";
import { GiveawayItemsComponent } from "./giveaway-items/giveaway-items.component";
import { SaveGiveawayItemComponent } from "./giveaway-items/save-giveaway-item/save-giveaway-item.component";
import { RepresentationComponent } from "./representation/representation.component";
import { SaveRepresentationsComponent } from "./representation/save-representations/save-representations.component";
import { SalesFileComponent } from "./sales-file/sales-file.component";
import { ItemFormatComponent } from './item-format/item-format.component';
import { AddItemFormatComponent } from './item-format/add-item-format/add-item-format.component';
import { ReactionComponent } from "./reaction/reaction.component";
import { ItemBrandComponent } from './item-brand/item-brand.component';
import { FeedbackComponent } from "./feedback/feedback.component";
import { ActivityCostCenterComponent } from "./activity-cost-center/activity-cost-center.component";
import { ActivityGroupComponent } from "./activity-group/activity-group.component";
import { ActivityCostTypeComponent } from "./activity-cost-type/activity-cost-type.component";
import { VendorComponent } from "./vendor/vendor.component";
import { BusinessUnitSharedBricksComponent } from "./business-unit-shared-bricks/business-unit-shared-bricks.component";
import { PharmacyWorkingDaysComponent } from "./pharmacy-working-days/pharmacy-working-days.component";

const routes: Routes = [
  
  { path: "jobs", component: JobsComponent, canActivate: [AuthGuard], data: { title: "Jobs" } },
  { path: "jobs/add", component: SaveJobComponent, canActivate: [AuthGuard], data: { title: "Add New Job" } },
  { path: "jobs/edit/:jobId", component: SaveJobComponent, canActivate: [AuthGuard], data: { title: "Edit Job" } },
  { path: "item-types", component: ItemTypeComponent, canActivate: [AuthGuard], data: { title: "Item Types" } },
  { path: "add-item-type", component: AddItemTypeComponent, canActivate: [AuthGuard], data: { title: "Add Item Type" } },
  { path: "add-item-type/:id", component: AddItemTypeComponent, canActivate: [AuthGuard], data: { title: "Add Item Type" } },

  { path: "hcp-classes", component: hcpClassComponent, canActivate: [AuthGuard], data: { title: "hcp Classes" } },

  { path: "hcps", component: HCPComponent, canActivate: [AuthGuard], data: { title: "HCPs" } },
  { path: "add-hcp", component: AddHCPComponent, canActivate: [AuthGuard], data: { title: "Add HCP" } },
  { path: "add-hcp/:hcpId", component: AddHCPComponent, canActivate: [AuthGuard], data: { title: "Add HCP" } },
  { path: "specialty", component: SaveSpecialityComponent, canActivate: [AuthGuard], data: { title: "Add Speciality" } },
  { path: "specialty/:specialityId", component: SaveSpecialityComponent, canActivate: [AuthGuard], data: { title: "Edit Speciality" } },


  { path: "business-units", component: BusinessUnitComponent, canActivate: [AuthGuard], data: { title: "Business Units" } },
  { path: "business-unit", component: SaveBusinessUnitComponent, canActivate: [AuthGuard], data: { title: "Add Business Unit" } },
  { path: "business-unit/:businessUnitId", component: SaveBusinessUnitComponent, canActivate: [AuthGuard], data: { title: "Edit Business Unit" } },
  { path: "hcp-class", component: hcpClassComponent, canActivate: [AuthGuard], data: { title: "hcp classes" } },

  { path: "business-unit-bricks", component: BusinessUnitBricksComponent, canActivate: [AuthGuard], data: { title: "Business Unit Bricks" } },
  { path: "manage-business-unit-bricks/:businessUnitId", component: ManageBusinessUnitBricksComponent, canActivate: [AuthGuard], data: { title: "Manage Business Unit Bricks" } },

  { path: "business-unit-types", component: BusinessUnitTypeComponent, canActivate: [AuthGuard], data: { title: "Business Unit Types" } },
  { path: "business-unit-type", component: SaveBusinessUnitTypeComponent, canActivate: [AuthGuard], data: { title: "Add Business Unit Type" } },
  { path: "business-unit-type/:businessUnitTypeId", component: SaveBusinessUnitTypeComponent, canActivate: [AuthGuard], data: { title: "Edit Business Unit Type" } },

  { path: "items", component: ItemComponent, canActivate: [AuthGuard], data: { title: "Items" } },
  { path: "add-item", component: AddItemComponent, canActivate: [AuthGuard], data: { title: "Add Item" } },
  { path: "add-item/:itemId", component: AddItemComponent, canActivate: [AuthGuard], data: { title: "Add Item" } },


  { path: "item-formats", component: ItemFormatComponent, canActivate: [AuthGuard], data: { title: "Item Formats" } },
  { path: "add-item-format", component: AddItemFormatComponent, canActivate: [AuthGuard], data: { title: "Add Format" } },
  { path: "add-item-format/:itemId", component: AddItemFormatComponent, canActivate: [AuthGuard], data: { title: "Add Format" } },

  { path: "item-brands", component: ItemBrandComponent, canActivate: [AuthGuard], data: { title: "Item Brands" } },

  { path: "giveaway-items", component: GiveawayItemsComponent, canActivate: [AuthGuard], data: { title: "Giveaway Items" } },
  { path: "giveaway-items/add", component: SaveGiveawayItemComponent, canActivate: [AuthGuard], data: { title: "Add giveaway item" } },
  { path: "giveaway-items/edit/:itemId", component: SaveGiveawayItemComponent, canActivate: [AuthGuard], data: { title: "Edit giveaway item" } },
  
  { path: "representations", component: RepresentationComponent, canActivate: [AuthGuard], data: { title: "Representations" } },
  { path: "representation/add", component: SaveRepresentationsComponent, canActivate: [AuthGuard], data: { title: "Add representation" } },
  { path: "representation/edit/:itemId", component: SaveRepresentationsComponent, canActivate: [AuthGuard], data: { title: "Edit representation" } },

  { path: "org-job-str", component: OrganizationJobStructureComponent, canActivate: [AuthGuard], data: { title: "Organization Job Structures" } },
  { path: "add-org-job-str", component: AddOrganizationJobStructureComponent, canActivate: [AuthGuard], data: { title: "Add organization Job Structure" } },
  { path: "add-org-job-str/:Id", component: AddOrganizationJobStructureComponent, canActivate: [AuthGuard], data: { title: "Add organization Job Structure" } },
  { path: "specialities", component: SpecialityComponent, canActivate: [AuthGuard], data: { title: "Specialities" } },


  { path: "organization-types", component: OrganizationTypeComponent, canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: "Organization Types" } },
  { path: "organization-type", component: AddOrganizationTypeComponent, canActivate: [AuthGuard], data: { title: "Organization Type" } },
  { path: "organization-type/:id", component: AddOrganizationTypeComponent, canActivate: [AuthGuard], data: { title: "Organization Type" } },

  { path: "hcp-classes", component: hcpClassComponent, canActivate: [AuthGuard], data: { title: "hcp Classes" } },
  { path: "add-hcp-class", component: AddhcpClassComponent, canActivate: [AuthGuard], data: { title: "Add hcpClass" } },
  { path: "add-hcp-class/:id", component: AddhcpClassComponent, canActivate: [AuthGuard], data: { title: "Add hcpClass" } },

  { path: "activity-types", component: ActivityTypeComponent, canActivate: [AuthGuard], data: { title: "activity types" } },
  { path: "add-activity-type", component: AddActivityTypeComponent, canActivate: [AuthGuard], data: { title: "activity types" } },
  { path: "add-activity-type/:id", component: AddActivityTypeComponent, canActivate: [AuthGuard], data: { title: "activity types" } },
  { path: "working-days", component: WorkingDaysComponent, canActivate: [AuthGuard], data: { title: "working days" } },
  { path: "pharmacy-working-days", component: PharmacyWorkingDaysComponent, canActivate: [AuthGuard], data: { title: "working days" } },

  { path: "holiday", component: holidayComponent, canActivate: [AuthGuard], data: { title: "holiday" } },
  { path: "add-holiday", component: AddHolidayComponent, canActivate: [AuthGuard], data: { title: "Add Holiday" } },
  { path: "add-holiday/:id", component: AddHolidayComponent, canActivate: [AuthGuard], data: { title: "Add Holiday" } },
  { path: "non-field-types", component: VacationTypeComponent, canActivate: [AuthGuard], data: { title: "Non field activity Type" } },
  { path: "vacation-types", component: VacationTypeComponent, canActivate: [AuthGuard], data: { title: "Vacation Type" } },
  { path: "add-vacation-type", component: VacationTypeAddComponent, canActivate: [AuthGuard], data: { title: "Add Vacation Type" } },
  { path: "add-vacation-type/:id", component: VacationTypeAddComponent, canActivate: [AuthGuard], data: { title: "Edit Vacation Type" } },

  { path: "add-non-field-type", component: VacationTypeAddComponent, canActivate: [AuthGuard], data: { title: "Add non field activity type" } },
  { path: "add-non-field-type/:id", component: VacationTypeAddComponent, canActivate: [AuthGuard], data: { title: "Edit non field activity type" } },

  { path: "coachings", component: CoachingComponent, canActivate: [AuthGuard], data: { title: "Coachings" } },
  { path: "add-coachings", component: AddCoachingComponent, canActivate: [AuthGuard], data: { title: "Add Coachings" } },
  { path: "add-coachings/:Id", component: AddCoachingComponent, canActivate: [AuthGuard], data: { title: "Add Coachings" } },
  { path: "sales-file", component: SalesFileComponent, canActivate: [AuthGuard], data: { title: "Sales File" } },
  { path: "reactions", component: ReactionComponent, canActivate: [AuthGuard], data: { title: "Reactions" } },
  { path: "feedback", component: FeedbackComponent, canActivate: [AuthGuard], data: { title: "Feedback" } },
  { path: "activity-group", component: ActivityGroupComponent, canActivate: [AuthGuard], data: { title: "Activity Group" } },
  { path: "activity-cost-center", component: ActivityCostCenterComponent, canActivate: [AuthGuard], data: { title: "Activity Cost Center" } },
  { path: "activity-cost-type", component: ActivityCostTypeComponent, canActivate: [AuthGuard], data: { title: "Activity Cost Type" } },
  { path: "vendor", component: VendorComponent, canActivate: [AuthGuard], data: { title: "Vendor" } },
  { path: "shared-bricks", component: BusinessUnitSharedBricksComponent, canActivate: [AuthGuard], data: { title: "Shared Bricks" } },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class LookupsRoutingModule { }
