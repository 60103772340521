import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ItemService } from '../item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Item } from '../../../models/lookups/item.model';
import { ItemFormat } from '../../../models/lookups/itemFormat.model';
import { ItemType } from '../../../models/lookups/itemType.model';
import { DisplayModes } from '../../../models/enums';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';



@Component({
  selector: 'crm-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css']
})
export class AddItemComponent implements OnInit {
  item: Item = new Item();
  itemFormat: ItemFormat = new ItemFormat();
  itemFormats: ItemFormat[] = [];
  itemBrands: ItemFormat[] = [];
  itemBrand: ItemFormat = new ItemFormat();
  businessUnits: BusinessUnit[] = [];
  itemType: ItemType = new ItemType();
  itemTypes: ItemType[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  //itemId: number;

  @ViewChild('itemTypeSelector')
  private itemTypeSelector;

  @ViewChild('itemFormatSelector')
  private itemFormatSelector;

  @ViewChild('itemBrandSelector')
  private itemBrandSelector;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() itemId: number;
  constructor(private readonly itemService: ItemService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {

    this.getItemFormats();
    this.getItemBrands();
    this.getItemTypes();
    this.getLines();
    if (this.itemId != undefined)
      this.getDetails(this.itemId);
  }

  getItemTypes() {
    this.itemService.getAllItemTypes().subscribe(response => {
      this.itemTypes = response as ItemType[];
      if (this.itemTypeSelector)
        setTimeout(() => this.itemTypeSelector.refresh());
    }, err => {
      console.log(err);
    });
  }

  getItemBrands() {
    this.itemService.getAllItemBrandss().subscribe(response => {
      this.itemBrands = response as ItemFormat[];
      if (this.itemFormatSelector)
        setTimeout(() => this.itemBrandSelector.refresh());
    }, err => {
      console.log(err);
    });
  }

  getItemFormats() {
    this.itemService.getAllItemFormats().subscribe(response => {
      this.itemFormats = response as ItemFormat[];
      if (this.itemBrandSelector)
        setTimeout(() => this.itemFormatSelector.refresh());
    }, err => {
      console.log(err);
    });
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      if (this.item.businessUnit)
        this.item.buisnessUnitId = this.item.businessUnit.id;

      if (!(this.item.itemFormatId > 0))
        this.item.itemFormatId = null;
      else
        this.item.itemFormatId = Number(this.item.itemFormatId);

      if (!(this.item.itemBrandId > 0))
        this.item.itemBrandId = null;
      else
        this.item.itemBrandId = Number(this.item.itemBrandId);

      if (!(this.item.itemTypeId > 0))
        this.item.itemTypeId = null;
      else
        this.item.itemTypeId = Number(this.item.itemTypeId);

      this.itemService.save(this.item).subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.item.id == 0)
          this.closeEvent.emit(true);
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }


  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.itemService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.item = response as Item;
      setTimeout(() => this.itemFormatSelector.refresh());
      setTimeout(() => this.itemBrandSelector.refresh());
      setTimeout(() => this.itemTypeSelector.refresh());
    }, err => {
      this.displayMode = DisplayModes.Error;

    });
  }
  getLines() {
    this.businessUnitService.getBusinessUnitsRootLevel().subscribe(response => {
      this.businessUnits = response as BusinessUnit[];
    }, error => { });
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }
}

