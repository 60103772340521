import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vendor } from '../../../models/lookups/vendor.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { VendorService } from '../vendor.service';
import { AttachementFileApiService } from '../../../services/attachement-file-api.service';
import { HttpEventType } from "@angular/common/http";
import { AttachmentFile } from "../../../expenses/models/attachement-file.model";

@Component({
  selector: 'crm-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  vendor: Vendor = new Vendor();

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() vendorId: number;
  progress;
  //files: AttachmentFile[] = [];
  constructor(private readonly vendorService: VendorService, private readonly attachmentFileApiService: AttachementFileApiService,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.vendorId != undefined)
      this.getVendor(this.vendorId);
  }

  getVendor(vendorId) {
    this.displayMode = DisplayModes.Loading;
    this.vendorService.getById(vendorId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.vendor = responce as Vendor;
      //this.files = this.vendor.files;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Vendor');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;
      //this.vendor.files = this.files;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.vendorService.save(this.vendor).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  onFileSelect(event) {
    console.log(event);
    if (event.addedFiles.length === 0)
      return;

    const formData = new FormData();

    for (let file of event.addedFiles)
      formData.append(file.name, file);

    this.attachmentFileApiService.upload(formData, "Vendor").subscribe(resp => {
      if (resp.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * resp.loaded / resp.total);
      else if (resp.type === HttpEventType.Response) {
        let file: AttachmentFile = resp.body as AttachmentFile;

        if (!this.vendor.files)
          this.vendor.files = [];
        this.vendor.files.push(file);
      }
    });
  }

  onFileRemove(file) {
    let index = this.vendor.files.indexOf(file);
    this.vendor.files.splice(index, 1);
  }

  openFile(url: string) {
    if (url != "" && url != undefined) {
      window.open(url, "_blank");
    }
  }

}
