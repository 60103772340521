<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!itemId">Create {{'Items' | translate}}</span>
    <span class="panel-title" *ngIf="itemId">Edit {{'Items' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!itemId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 padding-sm-vr">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'items.name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="item.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6 padding-sm-vr">
            <div>
              <label class="control-label">{{'items.brandName' | translate}} </label>
              <select id="itemBrandId" name="itemBrandId" [(ngModel)]="item.itemBrandId" #iemBrandlId="ngModel"
                      #itemBrandSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect
                      data-live-search="true" data-actions-box="false" data-live-search-placeholder="Search..." title="Select Item Brand">
                <option attr.data-content="<span title='{{'Select Item Brand'| translate}}' class='badge'>{{'Select Item Brand'| translate}}</span>" value="0">
                  {{'Select Item Brand'| translate}}
                </option>
                <option *ngFor="let item of itemBrands" attr.data-content="<span title='{{item.name}}'>{{item.name }}</span>" attr.value="{{item.id}}">
                  {{item.name}}
                </option>
              </select>

            </div>
          </div>

          <!--<div class="col-sm-6">
            <label class="control-label">{{'items.brandName' | translate}} </label>
            <input type="text"
                   name="brandName"
                   [(ngModel)]="item.brandName"
                   #brandName="ngModel"
                   class="form-control" />
          </div>-->
          <div class="col-sm-6 padding-sm-vr" *ngIf="!itemId">
            <label class="control-label">{{'items.publicPrice' | translate}} </label>
            <input type="text"
                   name="publicPrice"
                   [(ngModel)]="item.publicPrice"
                   #publicPrice="ngModel"
                   class="form-control" />
          </div>

          <div class="col-sm-6 padding-sm-vr" *ngIf="!itemId">
            <label class="control-label">{{'items.xFactorPrice' | translate}} </label>
            <input type="text"
                   name="xFactorPrice"
                   [(ngModel)]="item.xFactorPrice"
                   #xFactorPrice="ngModel"
                   class="form-control" />
          </div>
          <div class="col-sm-6 padding-sm-vr" *ngIf="!itemId">
            <label class="control-label">{{'items.tenderPrice' | translate}} </label>
            <input type="text"
                   name="tenderPrice"
                   [(ngModel)]="item.tenderPrice"
                   #tenderPrice="ngModel"
                   class="form-control" />
          </div>
          <div class="col-sm-6 padding-sm-vr" *ngIf="!itemId">
            <label class="control-label">{{'items.exportPrice' | translate}} </label>
            <input type="text"
                   name="exportPrice"
                   [(ngModel)]="item.exportPrice"
                   #exportPrice="ngModel"
                   class="form-control" />
          </div>
          <div class="col-sm-6 padding-sm-vr" *ngIf="!itemId">
            <label class="control-label">{{'items.averagePrice' | translate}} </label>
            <input type="text"
                   name="averagePrice"
                   [(ngModel)]="item.averagePrice"
                   #averagePrice="ngModel"
                   class="form-control" />
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div>

              <label class="control-label">{{'Category' | translate}} </label>
              <select id="itemTypelId" name="itemTypelId" [(ngModel)]="item.itemTypeId" #itemTypelId="ngModel"
                      #itemTypeSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect
                      data-live-search="true" data-actions-box="false" data-live-search-placeholder="Search..." title="Select Item Type">
                <option attr.data-content="<span title='{{'SelectItemCategory'| translate}}' class='badge'>{{'SelectItemCategory'| translate}}</span>" value="0">
                  {{'SelectItemCategory'| translate}}
                </option>
                <option *ngFor="let item of itemTypes" attr.data-content="<span title='{{item.name}}'>{{item.name }}</span>" attr.value="{{item.id}}">
                  {{item.name}}
                </option>
              </select>

            </div>
          </div>
          <div class="col-sm-6" *ngIf="!itemId">
            <div [ngClass]="{'has-success': (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.valid, 'has-error' : (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid}">
              <label class="control-label" for="businessUnitId-{{uniqueId}}">{{'items.businessUnit' | translate}}</label>
              <span class="ui-fluid">
                <p-dropdown [options]="businessUnits"
                            styleClass="wid100"
                            placeholder="Business Unit"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="businessUnitId" #businessUnitId="ngModel"
                            required="required" [(ngModel)]="item.businessUnit"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div>
              <label class="control-label">{{'items.itemFormat' | translate}} </label>
              <select id="itemFormatlId" name="itemFormatlId" [(ngModel)]="item.itemFormatId" #iemFormatlId="ngModel"
                      #itemFormatSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect
                      data-live-search="true" data-actions-box="false" data-live-search-placeholder="Search..." title="Select Item Format">
                <option attr.data-content="<span title='{{'SelectItemFormat'| translate}}' class='badge'>{{'SelectItemFormat'| translate}}</span>" value="0">
                  {{'SelectItemFormat'| translate}}
                </option>
                <option *ngFor="let item of itemFormats" attr.data-content="<span title='{{item.name}}'>{{item.name }}</span>" attr.value="{{item.id}}">
                  {{item.name}}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-12">

            <div class="pull-left">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="isEnabled" [(ngModel)]="item.isActive">
                  {{'Active' | translate}}
                </label>
              </div>

            </div>


          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>

